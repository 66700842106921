import { gql } from 'graphql-tag';
import { PriceSummaryFragment } from './fragments/priceSummary';

export const getPriceSummary = gql`
    query getPriceSummary($cartId: String!) {
        cart(cart_id: $cartId) {
            ...PriceSummaryFragment
        }
    }
    ${PriceSummaryFragment}
`;
