import { gql } from 'graphql-tag';

export const CartAddressFragment = gql`
    fragment CartAddressFragment on CartAddressInterface {
        city
        country {
            code
            label
        }
        firstname
        lastname
        company
        postcode
        region {
            code
            label
            region_id
        }
        street
        telephone
    }
`;

export const CustomerAddressFragment = gql`
    fragment CustomerAddressFragment on CustomerAddress {
        id
        firstname
        lastname
        street
        city
        region {
            region
            region_id
            region_code
        }
        postcode
        country_code
        telephone
        company
        default_billing
        default_shipping
        is_validated
    }
`;

export const BillingAddressFragment = gql`
    fragment BillingAddressFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        billing_address {
            ...CartAddressFragment
        }
    }
    ${CartAddressFragment}
`;
