import { gql } from 'graphql-tag';
import { GrandTotalPriceFragment } from '@graphql/operations/magento/usx/fragments/priceSummary';
import { ShippingAddressesFragment, AvailableShippingMethodsFragment } from './fragments/shipping';
import { BillingAddressFragment } from './fragments/address';
import { SelectedPaymentMethodFragment } from './fragments/payment';
import { AppliedCouponsFragment } from './fragments/couponCode';

/**
 * WARNING: Do not include the `AvailableShippingMethodsFragment` fragment as it will cause
 * slowdowns on the network request because it causes the backend to make remote queries
 * to carriers for real-time data. It is intentionally not included in this query. Make sure
 * you are conscious about the side effects when including it other queries and mutations.
 */
export const getCartSession = gql`
    query getCartSession($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            email
            prices {
                ...GrandTotalPriceFragment
            }
            ...AppliedCouponsFragment
            ...ShippingAddressesFragment
            ...BillingAddressFragment
            ...SelectedPaymentMethodFragment
        }
    }
    ${ShippingAddressesFragment}
    ${BillingAddressFragment}
    ${SelectedPaymentMethodFragment}
    ${AppliedCouponsFragment}
    ${GrandTotalPriceFragment}
`;

export const getCartSessionWithShippingMethods = gql`
    query getCartSessionWithShippingMethods($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            email
            ...ShippingAddressesFragment
            ...BillingAddressFragment
            ...SelectedPaymentMethodFragment
            ...AvailableShippingMethodsFragment
        }
    }
    ${ShippingAddressesFragment}
    ${BillingAddressFragment}
    ${SelectedPaymentMethodFragment}
    ${AvailableShippingMethodsFragment}
`;
