import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ea1650da&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VNavigationDrawer})

if (process.env.VUE_ENV === 'server') {
  const options = typeof component.exports === 'function'
    ? component.exports.extendOptions
    : component.options
  const existing = options.beforeCreate
  const hook = function () {
    require('vuetify/src/components/VApp/VApp.sass').__inject__(this.$ssrContext)
    require('vuetify/src/styles/main.sass').__inject__(this.$ssrContext)
    require('vuetify/src/components/VNavigationDrawer/VNavigationDrawer.sass').__inject__(this.$ssrContext)
    require('vuetify/src/components/VImg/VImg.sass').__inject__(this.$ssrContext)
    require('vuetify/src/components/VResponsive/VResponsive.sass').__inject__(this.$ssrContext)
    require('vuetify/src/components/VOverlay/VOverlay.sass').__inject__(this.$ssrContext)
  }
  options.beforeCreate = existing
    ? [].concat(existing, hook)
    : [hook]
}
    