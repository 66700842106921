import { gql } from 'graphql-tag';
import { CartAddressFragment } from './address';

/**
 * WARNING: This fragment can cause slowdowns on the network request because it
 * causes the backend to make remote queries to carriers for real-time data.
 * It is intentionally not included in the `getCartSession` query. Make sure you
 * are conscious about the side effects when including it in queries and mutations.
 */
export const AvailableShippingMethodsFragment = gql`
    fragment AvailableShippingMethodsFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        shipping_addresses {
            available_shipping_methods {
                amount {
                    currency
                    value
                }
                available
                carrier_code
                carrier_title
                method_code
                method_title
                delivery_date
            }
        }
    }
`;

export const SelectedShippingMethodFragment = gql`
    fragment SelectedShippingMethodFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        shipping_addresses {
            selected_shipping_method {
                amount {
                    currency
                    value
                }
                carrier_code
                carrier_title
                method_code
                method_title
            }
        }
    }
`;

export const ShippingAddressesFragment = gql`
    fragment ShippingAddressesFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        shipping_addresses {
            ...CartAddressFragment
        }
        ...SelectedShippingMethodFragment
    }
    ${CartAddressFragment}
    ${SelectedShippingMethodFragment}
`;
