import { getCartState } from '@graphql/operations/cart';
import { getCartItems } from '@graphql/operations/magento/usx/getCartItems';
import { getCartSession } from '@graphql/operations/magento/usx/getCartSession';
import { getPriceSummary } from '@graphql/operations/magento/usx/getPriceSummary';

/**
 * Get the default cached cart ID smart query configuration object.
 *
 * @return {Object}
 */
export const getDefaultCachedCartIdSmartQuery = () => ({
    query: getCartState,
    update: data => data.clientCart?.cachedId,
});

/**
 * Get the default cart items smart query configuration object.
 *
 * @return {Object}
 */
export const getDefaultCartItemsSmartQuery = () => ({
    query: getCartItems,
    update: data => {
        // Remove any `null` cart items. This happens if cart products are out of stock.
        if (data?.cart?.items?.length) {
            data.cart.items = data.cart.items.filter(item => item);
        }

        return data?.cart;
    },
    ...getDefaultCartIdVariableConfig(),
});

/**
 * Get the default cart session smart query configuration object.
 *
 * Note: This does not provide item details for the cart. This is user session cart details.
 *
 * @return {Object}
 */
export const getDefaultCartUserSessionSmartQuery = () => ({
    query: getCartSession,
    ...getDefaultCartIdVariableConfig(),
});

/**
 * Get the default cart price summary smart query configuration object.
 *
 * Note: This does not provide item details for the cart. This is user session cart details.
 *
 * @return {Object}
 */
export const getDefaultCartPriceSummarySmartQuery = () => ({
    query: getPriceSummary,
    ...getDefaultCartIdVariableConfig(),
});

/**
 * Get the default cart session smart query configuration object.
 *
 * Note: This does not provide item details for the cart. This is user session cart details.
 *
 * @return {Object}
 */
export const getDefaultCartIdVariableConfig = () => ({
    variables () {
        return {
            cartId: this.cartId,
        };
    },
    skip () {
        return !this.cartId;
    },
});

/**
 * Get the default cached cart static query configuration object.
 *
 * @return {Object}
 */
export const getDefaultCachedCartQuery = () => ({
    query: getCartState,
});

/**
 * Get the default cart items static query configuration object.
 *
 * @param  {String} cartId The cart ID.
 *
 * @return {Object}
 */
export const getDefaultCartItemsQuery = async (apollo) => {
    const { getCachedCartId } = await import('@utilities/cart/session');

    const cartId = await getCachedCartId(apollo);

    return {
        query: getCartItems,
        variables: {
            cartId,
        },
    };
};

/**
 * Get the default cart items static query configuration object for cache only.
 *
 * @return {Object}
 */
export const getDefaultCachedCartItemsQuery = async (apollo) => ({
    ...(await getDefaultCartItemsQuery(apollo)),
    fetchPolicy: 'cache-only',
});

/**
 * Get the default cart items static query configuration object.
 *
 * @param  {String} cartId The cart ID.
 *
 * @return {Object}
 */
export const getDefaultCartUserSessionQuery = async (apollo) => {
    const { getCachedCartId } = await import('@utilities/cart/session');

    const cartId = await getCachedCartId(apollo);

    return {
        query: getCartSession,
        variables: {
            cartId,
        },
    };
};

/**
 * Get the default cart items static query configuration object for cache only.
 *
 * @return {Object}
 */
export const getDefaultCachedCartUserSessionQuery = async (apollo) => ({
    ...(await getDefaultCartUserSessionQuery(apollo)),
    fetchPolicy: 'cache-only',
});

/**
 * Get the default cart items static query configuration object.
 *
 * @param  {String} cartId The cart ID.
 *
 * @return {Object}
 */
export const getDefaultCartPriceSummaryQuery = async (apollo) => {
    const { getCachedCartId } = await import('@utilities/cart/session');

    const cartId = await getCachedCartId(apollo);

    return {
        query: getPriceSummary,
        variables: {
            cartId,
        },
    };
};

/**
 * Get the default cart items static query configuration object for cache only.
 *
 * @return {Object}
 */
export const getDefaultCachedCartPriceSummaryQuery = async (apollo) => ({
    ...(await getDefaultCartPriceSummaryQuery(apollo)),
    fetchPolicy: 'cache-only',
});
