import { gql } from 'graphql-tag';
import { CartItemsFragment } from './fragments/cart';

export const getCartItems = gql`
    query getCartItems($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            ...CartItemsFragment
            applied_coupons {
                code
            }
        }
    }
    ${CartItemsFragment}
`;
