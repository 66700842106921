import { gql } from 'graphql-tag';
import { ProductCategoriesFragment } from './product';
import { ConfigurableProductOptionsFragment } from './ConfigurableProductOptionsFragment';
import { SelectedConfigurableOptionFragment } from './SelectedConfigurableOptionFragment';
import { ConfigurableAttributeOptionFragment } from './ConfigurableAttributeOptionFragment';
import { CustomizableOptionInterfaceFragment } from './CustomizableOptionInterfaceFragment';
import { SubscriptionIntervalFragment } from './SubscriptionIntervalFragment';

// Please be aware that updates to this fragment will likely require updates to the optimistic response handler
export const CartProductFragment = gql`
    fragment CartProductFragment on CartItemInterface {
        uid # Required for the Apollo cache unique fragment identifier
        id
        product {
            uid # Required for the Apollo cache unique fragment identifier
            id # Required for the Apollo cache unique fragment identifier
            sku
            name
            url_key
            main_image
            rx_required
            max_sale_qty
            displayable_in_stock
            displayable_allow_backorders
            contains_lithium
            # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
            product_availability
            product_availability_label
            product_revenue_rank
            price_tiers {
                quantity
            }
            advanced_pricing {
                msrp_display_actual_price_type
            }
            price_range {
                minimum_price {
                    final_price {
                        value
                    }
                    regular_price {
                        value
                    }
                    discount {
                        amount_off
                        percent_off
                    }
                }
            }
            # The 'manufacturer' key must always be included for 'brand' to return proper data
            manufacturer
            brand {
                id
                label
            }
            categories {
                ...ProductCategoriesFragment
            }
            ... on PhysicalProductInterface {
                weight
            }
            ... on ConfigurableProduct {
                configurable_options {
                    ...ConfigurableProductOptionsFragment
                }
                variants {
                    attributes {
                        ...ConfigurableAttributeOptionFragment
                    }
                    product {
                        uid # Required for the Apollo cache unique fragment identifier
                        id # Required for the Apollo cache unique fragment identifier
                        sku
                        name
                        main_image
                        url_key
                        displayable_in_stock
                        displayable_allow_backorders
                        # The 'product_availability' key must always be included for 'product_availability_label' to return proper data
                        product_availability
                        product_availability_label
                        price_range {
                            minimum_price {
                                final_price {
                                    value
                                }
                                regular_price {
                                    value
                                }
                                discount {
                                    amount_off
                                    percent_off
                                }
                            }
                        }
                    }
                }
            }
            ... on CustomizableProductInterface {
                options {
                    ...CustomizableOptionInterfaceFragment
                }
            }
            subscription_recommended_interval_id
            subscriptions {
                ...SubscriptionIntervalFragment
            }
        }
    }
    ${ProductCategoriesFragment}
    ${ConfigurableProductOptionsFragment}
    ${ConfigurableAttributeOptionFragment}
    ${CustomizableOptionInterfaceFragment}
    ${SubscriptionIntervalFragment}
`;

// Please be aware that updates to this fragment will likely require updates to the optimistic response handler
export const CartItemsFragment = gql`
    fragment CartItemsFragment on Cart {
        id
        items {
            ...CartProductFragment
            prices {
                price {
                    currency
                    value
                }
                total_item_discount {
                    value
                }
                discounts {
                    label
                    amount {
                        value
                    }
                }
            }
            quantity
            subscription {
                ...SubscriptionIntervalFragment
            }
            ... on SimpleCartItem {
                simple_cart_item_customizable_options: customizable_options {
                    customizable_option_uid # Required for the Apollo cache unique fragment identifier
                    values {
                        customizable_option_value_uid # Required for the Apollo cache unique fragment identifier
                    }
                }
            }
            ... on ConfigurableCartItem {
                configurable_options {
                    ...SelectedConfigurableOptionFragment
                }
                configurable_cart_item_customizable_options: customizable_options {
                    customizable_option_uid # Required for the Apollo cache unique fragment identifier
                    values {
                        customizable_option_value_uid # Required for the Apollo cache unique fragment identifier
                    }
                }
            }
            ... on BundleCartItem {
                children_discounts {
                    sku
                    total_discount
                }
                bundle_options {
                    uid # Required for the Apollo cache unique fragment identifier
                    values {
                        uid # Required for the Apollo cache unique fragment identifier
                        label
                        price
                        quantity
                        product {
                            uid # Required for the Apollo cache unique fragment identifier
                            id # Required for the Apollo cache unique fragment identifier
                            rx_required
                            sku
                            ... on ConfigurableProduct {
                                variants {
                                    attributes {
                                        ...ConfigurableAttributeOptionFragment
                                    }
                                    product {
                                        uid # Required for the Apollo cache unique fragment identifier
                                        id # Required for the Apollo cache unique fragment identifier
                                        sku
                                    }
                                }
                            }
                        }
                        configurable_options {
                            ...SelectedConfigurableOptionFragment
                        }
                    }
                }
            }
        }
    }
    ${CartProductFragment}
    ${SelectedConfigurableOptionFragment}
    ${SubscriptionIntervalFragment}
    ${ConfigurableAttributeOptionFragment}
`;
