import { gql } from 'graphql-tag';

export const AvailablePaymentMethodsFragment = gql`
    fragment AvailablePaymentMethodsFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        available_payment_methods {
            code
            title
        }
    }
`;

export const SelectedPaymentMethodFragment = gql`
    fragment SelectedPaymentMethodFragment on Cart {
        id # Required for the Apollo cache unique fragment identifier
        selected_payment_method {
            code
            title
        }
    }
`;
