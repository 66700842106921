var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VApp',{class:[
        _vm.$style.container,
        _vm.isProductAddToCartStickyActive && _vm.$style.productAddToCartStickyPadding,
        _vm.isHeaderFixedToTop && _vm.$style.headerFixedToTop ]},[_c('ClientOnly',[(_vm.hasFlashMessages)?_c('FlashMessageSticky'):_vm._e(),_vm._v(" "),(_vm.hasDialog)?_c('AppDialog'):_vm._e()],1),_vm._v(" "),_c('TopNavEmailOpt'),_vm._v(" "),(_vm.isMinimal)?_c('CheckoutHeader',{class:_vm.$style.mobileHeader}):_vm._e(),_vm._v(" "),_c('MainHeader',{class:_vm.isMinimal && _vm.$style.desktopHeader}),_vm._v(" "),_c('div',{class:_vm.$style.body},[_c('Nuxt')],1),_vm._v(" "),_c('MainFooter'),_vm._v(" "),_c('VNavigationDrawer',{class:_vm.$style.navigationDrawer,attrs:{"temporary":"","right":"","fixed":"","width":"340"},model:{value:(_vm.isContactFlyoutOpen),callback:function ($$v) {_vm.isContactFlyoutOpen=$$v},expression:"isContactFlyoutOpen"}},[(_vm.isContactFlyoutOpen)?_c('ContactFlyout'):_vm._e()],1),_vm._v(" "),(
            !_vm.isMobileMenuExpanded &&
            _vm.$posthogFeatureFlags.isFixedFooterMobileMenuActive ===
                'isFixedFooterMobileMenuActive'
        )?_c('MobileMenuFooterFixed',{class:[
            _vm.$style.mobileFooterMenuFixed,
            _vm.isProductAddToCartStickyActive && _vm.$style.mobileFooterMenuFixedStickyAddToCartOffset ]}):_vm._e(),_vm._v(" "),(_vm.$previewMode.isPreviewing)?_c('ContentPreviewNotification'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }