import { gql } from 'graphql-tag';

export const getCmsContent = gql`
    query getCmsContent($endpoint: String!, $preview: Boolean) {
        content(endpoint: $endpoint, preview: $preview) {
            ... on Content {
                endpoint # Required for the Apollo cache unique identifier
                preview
                system
                meta
                blocks {
                    name
                    data
                }
            }
            ... on ContentEmpty {
                endpoint
                preview
            }
        }
    }
`;
