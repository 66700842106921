<template>
    <VApp
        v-bind:class="[
            $style.container,
            isProductAddToCartStickyActive && $style.productAddToCartStickyPadding,
            isHeaderFixedToTop && $style.headerFixedToTop,
        ]"
    >
        <ClientOnly>
            <FlashMessageSticky v-if="hasFlashMessages" />
            <AppDialog v-if="hasDialog" />
        </ClientOnly>
        <TopNavEmailOpt />
        <CheckoutHeader v-if="isMinimal" v-bind:class="$style.mobileHeader" />
        <MainHeader v-bind:class="isMinimal && $style.desktopHeader" />
        <div v-bind:class="$style.body">
            <Nuxt />
        </div>
        <MainFooter />
        <VNavigationDrawer
            v-model="isContactFlyoutOpen"
            temporary
            right
            fixed
            v-bind:class="$style.navigationDrawer"
            width="340"
        >
            <ContactFlyout v-if="isContactFlyoutOpen" />
        </VNavigationDrawer>
        <MobileMenuFooterFixed
            v-if="
                !isMobileMenuExpanded &&
                $posthogFeatureFlags.isFixedFooterMobileMenuActive ===
                    'isFixedFooterMobileMenuActive'
            "
            v-bind:class="[
                $style.mobileFooterMenuFixed,
                isProductAddToCartStickyActive && $style.mobileFooterMenuFixedStickyAddToCartOffset,
            ]"
        />
        <ContentPreviewNotification v-if="$previewMode.isPreviewing" />
    </VApp>
</template>

<script>
    import { getFlashMessageState } from '@graphql/operations/flashMessage';
    import { getDialog } from '@graphql/operations/dialog';
    import { logResolvedRoute } from '@graphql/operations/routing';
    import { restoreCartSession } from '@utilities/cart/session';
    import { CPAP_THEME_COLOR_GREEN } from '@constants/theme';
    import { hydrateWhenVisible } from 'vue-lazy-hydration';
    import { getUserInterfaceState } from '@graphql/operations/userInterface';
    import {
        getDefaultCachedCartIdSmartQuery,
        getDefaultCartItemsSmartQuery,
    } from '@utilities/cartQueries';
    import { getDefaultConfig } from '@utilities/cms';
    import { isBroadcastChannelSupported } from '@utilities/isBroadcastChannelSupported';
    import { SESSION_REFRESH_CHANNEL } from '@constants/broadcastChannel';

    export default {
        /**
         * The Vue Apollo special option for queries in Vue components.
         *
         * @link https://apollo.vuejs.org/guide/apollo/
         */
        apollo: {
            flashMessage: {
                query: getFlashMessageState,
            },
            dialog: {
                query: getDialog,
            },
            userInterface: {
                query: getUserInterfaceState,
            },
            cartId: getDefaultCachedCartIdSmartQuery(),
            cart: {
                ...getDefaultCartItemsSmartQuery(),
                async result({ loading, data }) {
                    if (!loading) {
                        const [
                            { updateAbandonedCartProducts },
                            { setCheckoutLoadingStateByType },
                        ] = await Promise.all([
                            import('@utilities/vendorStorageManager'),
                            import('@utilities/checkout/setCheckoutLoadingStateByType'),
                        ]);

                        // Retrieve and update local storage with cart items
                        updateAbandonedCartProducts(data?.cart?.items || []);

                        // This force to revalidate the shipping address on payment process
                        // whenever the cart is updated
                        setCheckoutLoadingStateByType(
                            this.$apollo,
                            'hasValidShippingAddress',
                            false,
                        );
                    }
                },
                // Use `cache-only` to prevent this from making a cart request to the server
                fetchPolicy: 'cache-only',
            },
            topNavEmailOptBlock: getDefaultConfig(
                'announcement_bar___email_opt_intop_nav__email_opt_in',
            ),
        },

        /**
         * Declaratively register required components.
         *
         * @link https://vuejs.org/v2/guide/components.html#Local-Registration
         */
        components: {
            MainHeader: () => import('@components/MainHeader'),
            CheckoutHeader: () => import('@components/CheckoutHeader'),
            FlashMessageSticky: () => import('@components/FlashMessageSticky'),
            AppDialog: () => import('@components/Framework/AppDialog'),
            ContactFlyout: () => import('@components/ContactFlyout'),
            MainFooter: hydrateWhenVisible(() => import('@components/MainFooter')),
            // These should remain hydrated when visible even though
            // they have a conditional `v-if` as it lowers the TBT
            ContentPreviewNotification: hydrateWhenVisible(() =>
                import('@components/ContentPreviewNotification'),
            ),
            TopNavEmailOpt: hydrateWhenVisible(() => import('@components/TopNavEmailOpt')),
            MobileMenuFooterFixed: () => import('@components/MobileMenuFooterFixed'),
        },

        /**
         * You must define reactive data for all Vue models and Vue template data.
         *
         * @link https://vuejs.org/v2/api/#data
         *
         * @return Object Vue model and template binding defaults.
         */
        data: () => ({
            persistentDialog: null,
        }),

        /**
         * You must define defaults for all Vue models and reactive Vue template data.
         *
         * @link https://vuejs.org/v2/api/#data
         *
         * @return Object Vue model and binding defaults.
         */
        computed: {
            hasFlashMessages() {
                return ['error', 'success', 'information'].find(
                    type => this.flashMessage[`${type}Messages`]?.length,
                );
            },
            hasDialog() {
                return Boolean(this.dialog?.name || this.persistentDialog?.name || false);
            },
            isProductAddToCartStickyActive() {
                return Boolean(this.userInterface?.isProductAddToCartStickyActive);
            },

            /**
             * A Flag indicating whether or not the main mobile menu is expanded.
             *
             * @return {boolean}
             */
            isMobileMenuExpanded() {
                return Boolean(this.userInterface?.isMobileMenuExpanded);
            },

            isMinimal() {
                return Boolean(this.userInterface?.isMinimalCheckoutActive);
            },

            isHeaderFixedToTop() {
                return Boolean(this.userInterface?.isHeaderFixedToTop);
            },

            isContactFlyoutOpen: {
                get() {
                    return Boolean(this.userInterface?.isContactFlyoutOpen);
                },
                set(value) {
                    if (value !== this.userInterface?.isContactFlyoutOpen) {
                        this.setIsContactFlyoutOpen(value);
                    }
                },
            },
        },

        /**
         * This method logic will be run immediately after the Vue instance is created.
         *
         * @link https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
         */
        created() {
            // Determine if the route query has the preview flag
            // Fastly will remove the preview flag in staging and produciton environments
            if (this.$previewMode.isPreviewing && this.$route.query?.preview === 'true') {
                this.removePreviewModeQueryString();
            }
        },

        /**
         * This method logic will be run immediately after the Vue instance is mounted.
         *
         * @link https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
         */
        async mounted() {
            // Restore authentication state on initial app load.
            this.restoreAuthenticationState();

            // This will log the initial application resolved route on the
            // client, afterwards the middleware will log the route changes
            this.$apollo.mutate({
                mutation: logResolvedRoute,
                variables: {
                    path: this.$route.fullPath,
                },
            });

            // Determine if a URL fragment hash is present for anchor linking
            if (this.$route.hash) {
                // Set timeout to ensure component rendering is complete
                // and then scroll to the anchor element
                setTimeout(async () => {
                    // Defer loading of anchor scrolling utility until required
                    const { scrollToAnchor } = await import('@utilities/scrollToAnchor');

                    scrollToAnchor(this.$route.hash);
                }, 1500);
            }

            // Use the Vue root instance as an event bus for in-memory dialogs
            this.$root.setPersistentDialog = this.setPersistentDialog;
            this.$root.closePersistentDialog = this.closePersistentDialog;

            // Add user interaction event listeners to set global user `hasInteracted` state
            const { addListeners } = await import('@utilities/userInteractionEventListener');

            addListeners(this.onUserInteraction);

            this.initializeSessionRefreshChannel();
        },

        beforeDestroy() {
            if (this.sessionRefreshChannel) {
                this.sessionRefreshChannel.close();
            }
        },

        /**
         * Non-cached methods that are used by the Vue instance and template.
         *
         * @type Object ES6 methods
         */
        methods: {
            initializeSessionRefreshChannel() {
                if (isBroadcastChannelSupported()) {
                    this.sessionRefreshChannel = new BroadcastChannel(SESSION_REFRESH_CHANNEL);
                    this.sessionRefreshChannel.onmessage = () => {
                        if (document?.hidden) {
                            setTimeout(() => window.location.reload(), 1000);
                        }
                    };
                }
            },
            async setIsContactFlyoutOpen(isOpen) {
                const { setUserInterfaceState } = await import('@graphql/operations/userInterface');

                await this.$apollo.mutate({
                    mutation: setUserInterfaceState,
                    variables: {
                        isContactFlyoutOpen: isOpen,
                    },
                });
            },
            async onUserInteraction() {
                // Handle listeners
                this.setUserInteractionBoolean();

                // Handle GTM
                this.pushInteractionEvent();

                this.setClientSplitTestingVariantPlacement();

                // Load the user cart
                await this.loadCart();

                // If a promo query string exists, and this is not the `add-to-cart` page
                // attempt to add the promotion to the cart without blocking the main thread.
                // We will apply the promo on the `add-to-cart` page after items are added.
                if (!this.$route.path.startsWith('/add-to-cart')) {
                    this.automaticallySetPromotionCode();
                }

                // Add a delay after the initial user interaction to allow
                // for more critical JavaScript execution to be completed
                setTimeout(() => {
                    // Restore promo code, unless this is the `add-to-cart` page.
                    if (!this.$route.query?.promo && !this.$route.path.startsWith('/add-to-cart')) {
                        this.restorePromotionCode();
                    }

                    // Preload the homepage CMS content on user interaction to
                    // complete all top bar CMS navigation preloading. The navigation
                    // menu preloads are handled by user action with the menu itself.
                    if (this.$route?.path !== '/') {
                        this.preloadHomepage();
                    }

                    this.preloadSkeletonLoaderComponents();
                }, 2000);
            },

            async setGaUserId() {
                const { setDataLayerUserIdFromApollo } = await import(
                    '@utilities/gaTracking/trackerApi'
                );

                setDataLayerUserIdFromApollo(this.$apollo, this.$gtm);
            },

            async setAlgoliaUserToken() {
                const { setUserToken } = await import('@utilities/algolia/setUserToken');

                setUserToken(this.$config, this.$apollo);
            },

            async setUserInteractionBoolean() {
                const { setUserInterfaceState } = await import('@graphql/operations/userInterface');

                this.$apollo.mutate({
                    mutation: setUserInterfaceState,
                    variables: {
                        hasInteracted: true,
                    },
                });

                const { removeListeners } = await import('@utilities/userInteractionEventListener');

                removeListeners(this.onUserInteraction);
            },

            pushInteractionEvent() {
                this.$gtm.push({
                    event: 'initialUserInteraction',
                    hasInteracted: true,
                });
            },

            /**
             * Set the client state for an A/B test based on local cookie values. This
             * allows the vary header to control server-rendering on actively tested pages
             * while subsequent in-app route bucketing will be handled here.
             */
            async setClientSplitTestingVariantPlacement() {
                const { addSplitTest } = await import('@graphql/operations/splitTesting');

                const cookiesMap = this.$cookies.getAll();

                const splitTestQueries = Object.entries(cookiesMap || {})
                    // Map any A/B testing client-side cookies into
                    // storable Apollo mutations for local state
                    .map(([key, value]) => {
                        const [experimentId, variationId] = String(value).split('.');
                        const lowercaseKey = (key || '').toLowerCase();

                        return lowercaseKey.startsWith('ab-') && variationId
                            ? this.$apollo.mutate({
                                  mutation: addSplitTest,
                                  variables: {
                                      label: lowercaseKey.substring(3),
                                      experimentId,
                                      variationId: Number(variationId),
                                  },
                              })
                            : null;
                    })
                    // Filter out any null values
                    .filter(splitTest => splitTest);

                // Determine if any split test Apollo query promises exist
                if (splitTestQueries?.length) {
                    // Await for the local store to be set
                    await Promise.all(splitTestQueries);
                }
            },

            /**
             * Restores the local authentication state for an initial hard application load.
             */
            async restoreAuthenticationState() {
                const { setUserAuthState } = await import('@graphql/operations/user');

                const setAuthState = isAuthenticated =>
                    this.$apollo.mutate({
                        mutation: setUserAuthState,
                        variables: {
                            isAuthenticated,
                        },
                    });

                // Use the Vue Apollo helper to determine if the user has an authentication cookie
                if (this.$apolloHelpers.getToken()) {
                    let isAuthenticated = false;

                    const { authenticateCustomer } = await import(
                        '@graphql/operations/magento/usx/authenticateCustomer'
                    );

                    try {
                        // Verify that the cookie session is still active
                        await this.$apollo.query({
                            query: authenticateCustomer,
                        });

                        isAuthenticated = true;

                        // Push User-ID to data layer.
                        this.setGaUserId();
                        this.setAlgoliaUserToken();
                    } catch (error) {
                        // Logout the user state
                        await this.$apolloHelpers.onLogout();
                    }

                    setAuthState(isAuthenticated);
                } else {
                    setAuthState(false);
                }
            },

            /**
             * Restore or create a user cart session.
             */
            async loadCart() {
                try {
                    await restoreCartSession(this.$apollo);
                } catch (error) {
                    const { logError } = await import('@utilities/logError');

                    logError(error);
                }
            },

            /**
             * Remove the preview query string parameter from the the entry URL.
             */
            removePreviewModeQueryString() {
                const { preview, ...rest } = this.$route.query;

                this.$router.replace({
                    query: Object.keys(rest).length ? rest : null,
                });
            },

            /**
             * Automatically set the promo code if it's defined in the URL query string.
             */
            async automaticallySetPromotionCode() {
                if (this.$route.query?.promo) {
                    const { addPromotionCodeToCart } = await import(
                        '@utilities/addPromotionCodeToCart'
                    );

                    addPromotionCodeToCart(this.$apollo, this.$route.query.promo, this.$gtm);
                }
            },

            /**
             * Restore the a cart session promo code to the local store client cart.
             */
            async restorePromotionCode() {
                const [
                    { getDefaultCartItemsQuery, getDefaultCartUserSessionQuery },
                    { setPromoCode },
                ] = await Promise.all([
                    import('@utilities/cartQueries'),
                    import('@graphql/operations/cart'),
                ]);

                const cartItemsQuery = await getDefaultCartItemsQuery(this.$apollo);

                const { data: cartItemsData } = await this.$apollo.query(cartItemsQuery);

                // Determine if user has items in cart
                if (cartItemsData?.cart?.items?.length) {
                    const cartSessionQuery = await getDefaultCartUserSessionQuery(this.$apollo);

                    const { data: cartSession } = await this.$apollo.query(cartSessionQuery);

                    // Determine if the user has applied coupons on the cart session
                    if (cartSession?.cart?.applied_coupons?.length) {
                        // Save the promo code to the client cart
                        this.$apollo.mutate({
                            mutation: setPromoCode,
                            variables: {
                                code: cartSession.cart.applied_coupons[0].code || '',
                            },
                        });
                    }
                }
            },

            /**
             * Set in-memory dialog data that will persist on purged Apollo cache.
             */
            setPersistentDialog(data) {
                this.persistentDialog = data;
            },

            /**
             * Close an in-memory dialog.
             */
            closePersistentDialog() {
                this.persistentDialog = null;
            },

            /**
             * Preload the homepage on company icon hover or interaction.
             */
            async preloadHomepage() {
                const { preloadCmsPage } = await import('@utilities/preload/preloadCmsPage');

                preloadCmsPage(this.$nuxt, this.$apollo, `/`, this.$previewMode?.isPreviewing);
            },

            /**
             * Preload skeleton loader components which are used to prevent layout shifting
             * between routes. This should help prevent any FOUC on in-app routing.
             */
            async preloadSkeletonLoaderComponents() {
                await Promise.all([
                    import('@components/Framework/AppSkeleton'),
                    import('@components/ProductListingPage'),
                    import('@components/ProductListingPageSkeleton'),
                ]);
            },
        },

        /**
         * Programmatically construct the template head HTML.
         *
         * @return {Object}
         */
        head() {
            const isFixedFooterMobileMenuActive =
                this.$posthogFeatureFlags.isFixedFooterMobileMenuActive ===
                'isFixedFooterMobileMenuActive';

            return {
                style: [
                    ...(isFixedFooterMobileMenuActive
                        ? [
                              {
                                  cssText: `
                                #chat-window { display: none !important; }
                                #chat-button { display: none !important; }
                                #chat-campaigns { display: none !important; }
                                `,
                                  type: 'text/css',
                              },
                          ]
                        : []),
                ],
                link: [
                    // Preconnects
                    {
                        rel: 'preconnect',
                        href: 'https://www.googletagmanager.com',
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://www.google-analytics.com',
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://api.cpap.com',
                        crossorigin: true,
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://connect.facebook.net',
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://dx.steelhousemedia.com',
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://px.steelhousemedia.com',
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://developer.livehelpnow.net',
                        crossorigin: true,
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://cdn.livehelpnow.net',
                    },
                    {
                        rel: 'preconnect',
                        href:
                            'https://88559364175b176f8341-7518c0400f865eee1405574b58fa83a4.ssl.cf1.rackcdn.com',
                    },

                    // DNS Prefetching
                    {
                        rel: 'dns-prefetch',
                        href: 'https://www.googletagmanager.com',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://www.google-analytics.com',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://api.cpap.com',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://connect.facebook.net',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://dx.steelhousemedia.com',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://px.steelhousemedia.com',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://connect.facebook.net',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://developer.livehelpnow.net',
                    },
                    {
                        rel: 'dns-prefetch',
                        href: 'https://cdn.livehelpnow.net',
                    },
                    {
                        rel: 'dns-prefetch',
                        href:
                            'https://88559364175b176f8341-7518c0400f865eee1405574b58fa83a4.ssl.cf1.rackcdn.com',
                    },

                    // Icons and favicons ( http://realfavicongenerator.net )
                    {
                        rel: 'apple-touch-icon',
                        sizes: '180x180',
                        href: '/apple-touch-icon.png',
                    },
                    {
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '32x32',
                        href: '/favicon-32x32.png',
                    },
                    {
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '16x16',
                        href: '/favicon-16x16.png',
                    },
                    {
                        rel: 'manifest',
                        href: '/site.webmanifest',
                    },
                    {
                        rel: 'mask-icon',
                        href: '/safari-pinned-tab.svg',
                        color: CPAP_THEME_COLOR_GREEN,
                    },

                    // Meta for third parties
                    {
                        rel: 'publisher',
                        href: 'https://plus.google.com/110162159293012842537',
                    },
                ],
                meta: [
                    {
                        hid: 'charset',
                        charset: 'utf-8',
                    },
                    {
                        name: 'HandheldFriendly',
                        content: 'True',
                    },
                    {
                        name: 'MobileOptimized',
                        content: '320',
                    },
                    {
                        hid: 'viewport',
                        name: 'viewport',
                        content: 'width=device-width, initial-scale=1',
                    },
                    {
                        hid: 'description',
                        name: 'description',
                        content:
                            'Find all the information you need to make the most of CPAP therapy and treat Sleep Apnea. Read tons of reviews on CPAP Machines, Masks & Supplies.',
                    },
                    // Icons and favicons ( http://realfavicongenerator.net )
                    {
                        hid: 'apple-mobile-web-app-title',
                        name: 'apple-mobile-web-app-title',
                        content: 'CPAP.com',
                    },
                    {
                        hid: 'application-name',
                        name: 'application-name',
                        content: 'CPAP.com',
                    },
                    {
                        hid: 'msapplication-TileColor',
                        name: 'msapplication-TileColor',
                        content: '#00a300',
                    },
                    {
                        hid: 'theme-color',
                        name: 'theme-color',
                        content: CPAP_THEME_COLOR_GREEN,
                    },

                    // Meta for third parties
                    {
                        name: 'google-site-verification',
                        content: 'wYqVbCtxN_YM1tLTZmGkUDbEmNrSjpB7hBNp5_Avsvs',
                    },
                    {
                        name: 'google-site-verification',
                        content: '0ThvAQD5s9EGpJrG1agpLu2n3hNnSVFH-EqtveeKmBE',
                    },
                    {
                        hid: 'p:domain_verify',
                        name: 'p:domain_verify',
                        content: '3385aa4b2cd84cd4aa94fbe8eda39942',
                    },
                    {
                        hid: 'msvalidate.01',
                        name: 'msvalidate.01',
                        content: '78835B2E34E9A1ADC481F360D956C820',
                    },
                    {
                        hid: 'verify-a',
                        name: 'verify-a',
                        value: '683a226180f4f8f2be0c',
                    },
                ],
            };
        },
    };
</script>

<!-- This should generally be the only global CSS in the app. -->
<style module>
    @import 'icons';

    /* Target Vuetify v-icon component SVGs globally since we don't use FA library/css */
    @mixin vuetifyIconOverrides;

    /* stylelint-disable-next-line selector-max-type */
    body {
        @apply overflow-x-hidden;
    }

    .container {
        /* Bottom padding needed to prevent chat from overlaying on top of footer content */
        @apply font-default font-normal text-cpap-theme-gray-9 pb-12;
        @apply lg:pb-4;
    }

    .body {
        @apply my-0;

        @screen lg {
            min-width: $size-content-width-min;
        }
    }

    .productAddToCartStickyPadding {
        padding-bottom: 7rem;

        @apply lg:pb-20;
    }

    /* Global vendor styles */
    [v-cloak] {
        @apply hidden;
    }

    :global([id^='lightbox-']),
    :global([id^='web-chat-']),
    :global(#chat-button),
    :global(#chat-window),
    :global(#chat-campaigns) {
        @media print {
            display: none !important;
        }
    }

    .navigationDrawer {
        &:global(.v-navigation-drawer--temporary) {
            /* Because of third-party DOM elements from Digioh and LHN */
            /* with massive z-indices this needs to be a large number */
            z-index: 9999999;
        }
    }

    .desktopHeader {
        @apply hidden invisible md:block md:visible;
    }

    .mobileHeader {
        @apply block md:hidden md:invisible;
    }

    .mobileFooterMenuFixed {
        @apply fixed bottom-0 right-0 left-0 top-auto;
        @apply md:hidden;

        :global(.v-navigation-drawer__content) {
            @apply h-auto;
        }
    }

    .mobileFooterMenuFixedStickyAddToCartOffset {
        bottom: 49px;
    }

    .headerFixedToTop {
        padding-top: 112px;
    }
</style>
