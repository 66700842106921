import { getCmsContent } from '@graphql/operations/cmsContent';
import _get from 'lodash/get';

/**
 * Get the standard query variables for the Apollo CMS query.
 *
 * @return {Object}
 */
export const getQueryVariables = (route, isPreviewing) => ({
    endpoint: route.path || route,
    // Allows content managers to use Kentico preview mode
    preview: isPreviewing || false,
});

/**
 * Get all response blocks from the Apollo CMS response.
 *
 * @return {Array}
 */
export const getBlocks = response => response?.content?.blocks || response?.blocks || [];

/**
 * Get the named block data from the Apollo CMS response.
 *
 * @return {Object}
 */
export const getBlock = (response, name, path = '') => {
    const { data } = (getBlocks(response) || []).find(block => block.name === name) || {};

    return path ? _get(data, path) : data;
};

/**
 * Get the page data from the Apollo CMS response.
 *
 * @return {Object}
 */
export const getDefaultConfig = route => ({
    query: getCmsContent,
    variables () {
        return getQueryVariables(route || this?.$route, this?.$previewMode?.isPreviewing);
    },
    update (response) {
        return response.content;
    },
});

/**
 * Get the page data from the Apollo CMS response and merge the first overriding block to the default block.
 *
 * @param {string} route    The Kentico endpoint.
 * @param {string} path     The property path to get from found block.
 * @param {object} product  Magento product instance to match against from CMS values.
 *
 * @return {object}
 */
export const getMergedPdpConfigBlock = (route, path, product, stockStatus = 1) => ({
    ...getDefaultConfig(route),
    update ({ content }) {
        const defaultData = content?.blocks?.[0]?.data || {};
        let overridingData = {};
        const productCategoryIds = product?.categories?.length
            ? product.categories.map(({ id }) => Number(id))
            : [];
        const productManufacturerLabel = ((product?.brand || product?.manufacturer)?.label || '').toLowerCase();
        const overridingBlocks = (content?.blocks || []).find(({ name }) => name === 'conditionalOverrides')?.data?.globalPdpPageConditionals || [];
        const defaultDataClone = Object.assign({}, defaultData);

        /**
         * Merge non-empty/lengthy keyed data into an object with overlapping keys.
         *
         * @param {object} originalObject The original object which we merge into.
         * @param {object} mergingObject  The overriding data to merge into original object.
         *
         * @return {object}
         */
        const mergeObjects = (originalObject, mergingObject) => {
            const originalObjectClone = Object.assign({}, originalObject);
            const overridingDataKeys = Object.keys(mergingObject || {});

            if (overridingDataKeys.length) {
                overridingDataKeys.forEach(key => {
                    const overridingValue = mergingObject[key];
                    const isArray = Array.isArray(overridingValue);

                    if (isArray ? overridingValue.length : overridingValue) {
                        Object.assign(originalObjectClone, { [key]: overridingValue });
                    }
                });
            }

            return originalObjectClone;
        };

        for (let i = 0; i < overridingBlocks.length; i++) {
            const overridingBlock = overridingBlocks[i];
            const overridingMatchTypeName = overridingBlock?.type?.[0]?.name;
            const overridingMatchTypeValues = (overridingBlock?.value || '').split(',');
            const overridingMatchTypeNamesValid = ['skus', 'category_ids', 'brands', 'stock_status'];
            const hasOverrides = product
                && overridingMatchTypeValues?.length
                && overridingMatchTypeName
                && overridingMatchTypeNamesValid.includes(overridingMatchTypeName);

            if (hasOverrides) {
                const hasCategoryMatch = overridingMatchTypeName === 'category_ids'
                    && productCategoryIds.some(productCategoryId => overridingMatchTypeValues
                        .map(overridingId => Number(overridingId)).includes(productCategoryId));

                const hasBrandMatch = overridingMatchTypeName === 'brands'
                    && overridingMatchTypeValues.map(brand => brand.toLowerCase()).includes(productManufacturerLabel);
                const hasStockStatusMatch = overridingMatchTypeName === 'stock_status'
                    && overridingMatchTypeValues.map(value => Number(value)).includes(stockStatus);
                const hasSkuMatch = overridingMatchTypeName === 'skus' && overridingMatchTypeValues.includes(product.sku);

                if (hasCategoryMatch || hasBrandMatch || hasStockStatusMatch || hasSkuMatch) {
                    // Iteratively merge the overriding data matches from first match to last match.
                    overridingData = mergeObjects(overridingData, overridingBlock?.overridingDataset?.[0]);
                }
            }
        };

        const mergedCmsData = mergeObjects(defaultDataClone, overridingData);

        return path && Object.keys(mergedCmsData || {}).length ? _get(mergedCmsData, path) : mergedCmsData;
    },
});

/**
 * Get the named block data from the Apollo CMS response.
 *
 * @return {Object}
 */
export const getDefaultBlockConfig = (blockName, blockPath = '', route = '') => ({
    ...getDefaultConfig(route),
    update (response) {
        return blockName ? getBlock(response, blockName, blockPath) : response.content;
    },
});

/**
 * Get the named block data from the Apollo CMS response.
 *
 * @return {Object}
 */
export const getDefaultFirstBlockConfig = (route = '') => ({
    ...getDefaultConfig(route),
    update (response) {
        return (getBlocks(response) || [])[0]?.data;
    },
});

/**
 * Get the page data from the Apollo CMS response for non-smart queries.
 *
 * @return {Object}
 */
export const getStaticDefaultConfig = (route, isPreviewing) => {
    const variables = getQueryVariables(route, isPreviewing);

    return {
        query: getCmsContent,
        variables,
    };
};

/**
 * Get the page data from the Apollo CMS response for non-smart queries.
 *
 * @return {Object}
 */
export const fetchStaticQuery = async (apollo, route, isPreviewing) => {
    const { data } = await apollo.query(getStaticDefaultConfig(route, isPreviewing));

    return data;
};

/**
 * Get the named block data from the Apollo CMS response.
 *
 * @return {object}
 */
export const fetchStaticBlock = async (apollo, route, blockName, {
    blockPath = '',
    isPreviewing = false,
} = {}) => {
    const response = await fetchStaticQuery(apollo, route, isPreviewing);

    return blockName ? getBlock(response, blockName, blockPath) : response?.content;
};

/**
 * Get the named block data from the Apollo CMS response.
 *
 * @return {Object}
 */
export const fetchStaticFirstBlock = async (apollo, route, isPreviewing) => {
    const response = await fetchStaticQuery(apollo, route, isPreviewing);

    return (getBlocks(response) || [])[0]?.data;
};
